import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import { BACKOFFICE_ACCESS_SKU, SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import { PRODUCT_STEP_HIDDEN_PRODUCTS } from '@/settings/Purchase';
import Cart from '@/util/Cart';
import Products from '@/util/Products';
import Packages from '@/util/GeneralInformation';

export default {
	mixins: [ConfigurableProducts],
	data() {
		return {
			memberProd: new Products(),
			packData: new Packages(),
			prodData: new Products(),
			getCartTotalsEstimates: new Cart(),
			activePackageId: 0,
			purchase: {},
			price_format: '',
			exchange_price_format: '',
			selectableProducts: {},
		};
	},
	computed: {
		memberProducts() {
			try {
				return this.memberProd.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingMemberProducts() {
			try {
				return this.memberProd.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasMemberProducts() {
			return !!this.memberProducts.length;
		},
		packageStep() {
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const step in this.steps) {
				// eslint-disable-next-line no-restricted-syntax
				for (const stepName in this.steps[step]) {
					if (this.steps[step][stepName]?.validation === 'product') {
						return stepName;
					}
				}
			}
			return undefined;
		},
		packages() {
			try {
				return this.packData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingPackages() {
			try {
				return this.packData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasPackages() {
			return !!this.packages.length;
		},
		activePackage() {
			const [response] = this.packages.filter((item) => {
				if (item.id === this.activePackageId) {
					return item;
				}
				return null;
			});
			if (typeof response === 'undefined') {
				return {};
			}
			return response;
		},
		savedPackage() {
			const step = this.packageStep;
			const data = { ...this.getStepInformation(step) };
			return data;
		},
		orderedPackages() {
			const orderedNumbers = {};
			this.packages.forEach((item) => {
				orderedNumbers[item.attributes.sort_order] = item.id;
			});
			return orderedNumbers;
		},
		products() {
			try {
				const mainProducts = [];
				const otherProducts = [];
				const wearablesProducts = [];
				const productsData = this.prodData.data.response.data.data;
				productsData.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});
				productsData.forEach((item) => {
					if (PRODUCT_STEP_HIDDEN_PRODUCTS.becomeDistributor.includes(item.attributes.sku)) return;

					// The '[price]' string will be replaced by the subtotal amount
					if (this.price_format.length === 0) {
						this.price_format = item.attributes.price.formatted_amount.replace(item.attributes.price.min_price, '[price]');
					}
					if (this.exchange_price_format.length === 0) {
						this.exchange_price_format = item.attributes.exchange_price.formatted_amount.replace(item.attributes.exchange_price.min_price, '[price]');
					}
					if (item.attributes.category_code === 'main') {
						mainProducts.push(item);
					} else if (item.attributes.category_code === 'other') {
						otherProducts.push(item);
					} else if (item.attributes.category_code === 'wearables') {
						wearablesProducts.push(item);
					}
				});
				return [...mainProducts, ...otherProducts, ...wearablesProducts];
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasProducts() {
			return !!this.products.length;
		},
		loadingTotalsEstimates() {
			return this.getCartTotalsEstimates.data.loading;
		},
		totalsEstimates() {
			try {
				return this.getCartTotalsEstimates.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
		totalVolume() {
			try {
				return this.totalsEstimates.volume || 0;
			} catch (error) {
				return 0;
			}
		},
		subtotal() {
			try {
				return this.totalsEstimates.subtotal || this.formatPriceNumber(0);
			} catch (error) {
				return this.formatPriceNumber(0);
			}
		},
		numericSubtotal() {
			try {
				return this.totalsEstimates.numeric_subtotal || 0;
			} catch (error) {
				return 0;
			}
		},
		exchangeSubtotal() {
			try {
				return this.totalsEstimates.exchange_subtotal || this.formatPriceNumber(0);
			} catch (error) {
				return this.formatPriceNumber(0);
			}
		},
		thereIsExchange() {
			return typeof this.totalsEstimates.exchange_subtotal !== 'undefined';
		},
		discounts() {
			try {
				return { ...this.getCartTotalsEstimates.data.response.data.response.discounts };
			} catch (error) {
				return {};
			}
		},
		hasDiscounts() {
			return !!Object.keys(this.discounts).length;
		},
	},
	watch: {
		totalVolume(newVal) {
			const lastPackageId = Object.values(this.orderedPackages)[Object.keys(this.orderedPackages).length - 1];
			let candidatePackage;
			let nextPackage;

			do {
				candidatePackage = nextPackage || this.activePackage;
				const nextPackageId = this.orderedPackages[candidatePackage.attributes.sort_order + 1];
				[nextPackage] = this.packages.filter((item) => item.id === String(nextPackageId));
			} while (
				candidatePackage.id < lastPackageId
				&& typeof nextPackage !== 'undefined'
				&& newVal >= nextPackage.attributes.volume_required
			);

			this.activePackageId = candidatePackage.id;
		},
	},
	methods: {
		loadPackageInfo() {
			return this.packData.getPackages().then(() => {
				if (this.hasPackages) {
					let savedPackage = '';
					if (typeof this.savedPackage.product !== 'undefined') {
						savedPackage = this.packages.find((item) => item.attributes.code_name === this.savedPackage.product.package_code_name);
					}

					if (typeof savedPackage === 'object' && typeof savedPackage.id !== 'undefined') {
						this.activePackageId = savedPackage.id;
					} else {
						this.activePackageId = this.packages[0].id;
					}
				}
			});
		},
		loadProductsInfo() {
			return this.prodData.getProducts(this.getStoredCountry(), undefined, { isRegister: true }).then(() => {
				this.price_format = '';
				this.exchange_price_format = '';
				if (typeof this.savedPackage.product === 'undefined' || Object.keys(this.savedPackage.product.products).length === 0) {
					this.clearProducts();
				} else {
					this.products.forEach((item) => {
						const { sku } = item.attributes;

						if (item.attributes.has_configurations) {
							const configs = this.getProductConfigs(item);
							Object.keys(configs).forEach((config) => {
								const configSku = this.getConfiguredProductSku(item, config);
								this.$set(this.purchase, configSku, this.getStoredProductQty(configSku));
							});
							return;
						}

						this.$set(this.purchase, sku, this.getStoredProductQty(sku));
					});

					const selectedExtraProducts = this.savedPackage.product.selectable_products;
					if (typeof selectedExtraProducts !== 'undefined') {
						Object.keys(selectedExtraProducts).forEach((valueCodeName) => {
							this.$set(this.selectableProducts, valueCodeName, selectedExtraProducts[valueCodeName]);
						});
					}
				}
			});
		},
		getStoredProductQty(sku) {
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			const currentPackage = this.savedPackage?.product?.package_code_name;
			const storedQty = this.savedPackage?.product?.products[sku];

			if (!!currentPackage && typeof storedQty !== 'undefined') {
				const value = parseInt(storedQty, 10);
				return Number.isNaN(value) ? 0 : value;
			}

			return 0;
		},
		selectPackage(id) {
			if (typeof id !== 'undefined') {
				this.activePackageId = id;
			}

			this.clearProducts();
		},
		clearProducts() {
			const products = {};
			Object.keys(this.purchase).forEach((sku) => {
				if (sku !== '') {
					products[sku] = 0;
				}
			});

			Object.keys(products).forEach((sku) => {
				this.$set(this.purchase, sku, products[sku]);
			});
		},
		updateTotalsEstimates() {
			const products = { [BACKOFFICE_ACCESS_SKU]: 1 };

			Object.keys(this.purchase).forEach((sku) => {
				if (this.purchase[sku]) {
					products[sku] = this.purchase[sku];
				}
			});

			return this.getCartTotalsEstimates.getCartTotalsEstimates({
				products,
				is_register: true,
				package_code_name: this.packages.find((item) => {
					const packageId = this.activePackageId ?? this.savedPackage.id;
					return item.id === packageId;
				}).attributes.code_name,
			});
		},
		formatPriceNumber(amount) {
			// With commas and 2 decimals
			return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		},
	},
};
