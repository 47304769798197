<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('account_and_shipping') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<li class="list-group-item pb-0 border-bottom-0">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('personal_information') }}
									</div>
									<div class="my-2">
										<span>{{ translate('are_you_a_company') }}: </span>
										<switch-toggle
											v-model="isCompany"
											variant="success"
											pill
											class="ml-3" />
									</div>
								</div>
							</div>
						</li>
						<li
							class="list-group-item">
							<div
								:class="{'m-3': !['xs','sm'].includes(windowWidth)}"
								class="row">
								<div class="col-12 col-md-4">
									<label>
										{{ translate('gender') }}
										<span class="text-danger">*</span>
									</label>
									<div class="form-group">
										<div class="row">
											<div class="col">
												<select
													id="gender"
													v-model="form.gender"
													:class="(typeof validationErrors[`personal_information.gender`] !== 'undefined') ? 'is-invalid' : ''"
													type="text"
													name="gender"
													autocomplete="gender"
													class="form-control">
													<option
														v-for="genderName in gender"
														:key="genderName"
														:value="genderName">
														{{ translate(genderName) }}
													</option>
												</select>
											</div>
										</div>
										<template v-if="(typeof validationErrors[`personal_information.gender`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`personal_information.gender`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<label>
										{{ translate('birthdate') }}
										<span class="text-danger">*</span>
									</label>
									<div class="form-group">
										<div class="row">
											<div class="col pr-0 pr-lg-3">
												<select
													id="year"
													v-model="form.birthdate.year"
													:class="(typeof validationErrors[`personal_information.birthdate`] !== 'undefined') ? 'is-invalid' : ''"
													type="text"
													name="year"
													autocomplete="bday-year"
													class="form-control">
													<option
														v-for="year in years()"
														:key="year"
														:value="year">
														{{ year }}
													</option>
												</select>
											</div>
											<div class="col px-0 px-lg-3">
												<select
													id="month"
													v-model="form.birthdate.month"
													:class="(typeof validationErrors[`personal_information.birthdate`] !== 'undefined') ? 'is-invalid' : ''"
													type="text"
													name="month"
													autocomplete="bday-month"
													class="form-control">
													<option
														v-for="month in months"
														:key="month.key"
														:value="month.value">
														{{ translate(month.key) }}
													</option>
												</select>
											</div>
											<div class="col pl-0 pl-lg-3">
												<select
													id="day"
													v-model="form.birthdate.day"
													:class="(typeof validationErrors[`personal_information.birthdate`] !== 'undefined') ? 'is-invalid' : ''"
													type="text"
													name="day"
													autocomplete="bday-day"
													class="form-control">
													<option
														v-for="day in 31"
														:key="day"
														:value="day <= 9 ? `0${day}` : day">
														{{ day | withCero }}
													</option>
												</select>
											</div>
										</div>
										<template v-if="(typeof validationErrors[`personal_information.birthdate`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`personal_information.birthdate`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									v-if="!isCompany && isNINRequired()"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`personal_information.national_identification_number`"
											:label="translate(`${userCountry}`)"
											:setter-value="form.national_identification_number"
											:errors="validationErrors[`personal_information.national_identification_number`]"
											:required="true"
											type="text"
											autocomplete="off"
											@dataChanged="form.national_identification_number = $event" />
									</div>
								</div>
								<div
									v-if="isCompany"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`personal_information.tax_id`"
											:label="translate('tax_id')"
											:setter-value="form.tax_id"
											:errors="validationErrors[`personal_information.tax_id`]"
											:required="true"
											type="text"
											autocomplete="off"
											@dataChanged="form.tax_id = $event" />
									</div>
								</div>
								<div
									v-if="isCompany"
									class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`personal_information.company`"
											:label="translate('company')"
											:setter-value="form.company"
											:errors="validationErrors[`personal_information.company`]"
											:required="true"
											type="text"
											autocomplete="organization"
											@dataChanged="form.company = $event" />
									</div>
								</div>
							</div>
						</li>
					</form>
					<li class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate( addAddress ? 'add_new_address' : 'select_your_address') }}
								</div>
							</div>
						</div>
						<div
							:class="{'px-4': windowWidth === 'md', 'px-3': !['xs','md'].includes(windowWidth), 'mx-3': !['xs','sm'].includes(windowWidth)}"
							class="row">
							<div
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12 address-list">
								<address-radio-button
									:address-data="addressData"
									:address-loading="addressLoading"
									:form-import="form"
									:hide-new-address="addAddress"
									:display-add-new-address="false"
									:borderless="true"
									@add-address="addressFormState" />
								<div
									v-if="!addAddress && !addressLoading"
									class="row">
									<div class="col-12">
										<hr class="mt-0">
									</div>
								</div>
								<div
									v-if="addAddress && !addressLoading"
									class="pt-4">
									<address-country-form
										:address="ADDRESSES.shipping"
										:user-country="true"
										:form-import="form"
										:errors-import="addressErrors.errors"
										:prefix-errors="''"
										:display-cancel-button="false"
										:display-submit-button="false"
										:display-goback-button="false"
										:display-override-toggle="showOverrideValidation" />
								</div>
							</div>
						</div>
						<div
							v-if="!addressLoading"
							:class="{'px-4': windowWidth === 'md', 'px-3': !['xs','md'].includes(windowWidth), 'mx-3': !['xs','sm'].includes(windowWidth)}"
							class="row">
							<div
								:class="{'mb-3': ['xs', 'sm'].includes(windowWidth), 'px-0': !['xs'].includes(windowWidth) }"
								class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
								<button
									v-if="addAddress && hasAddressData"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('see_address_list') }}
								</button>
								<button
									v-else-if="!addAddress"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
									class="btn btn-link b-4 float-left"
									@click="addressFormState">
									{{ translate('use_another_address') }}
								</button>
							</div>
							<div
								v-if="addAddress"
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
								<button
									:disabled="(!hasAddressData && !addAddress) || (!form.disclaimer && ADDITIONAL_ADDRESS_AGREEMENT_COUNTRIES.includes(country))"
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-outline-primary b-4 float-right"
									@click="newAddress(form)">
									{{ translate('save_address_button') }}
								</button>
							</div>
							<div
								v-if="(typeof validationErrors[`${steps[$route.name].validation}.shipping_address.address_id`] !== 'undefined')"
								id="address"
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12">
								<b-alert
									variant="danger"
									show>
									<div
										v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.address_id`]"
										:key="error">
										<span
											v-text="error" />
										<br>
									</div>
								</b-alert>
							</div>
						</div>
					</li>
					<li
						v-if="!loadingCartInfo && Object.keys(cartInfo.tickets).length"
						class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3">
								<div class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('event_attendees') }}</span>
										</div>
										<p
											v-if="tickets"
											class="mt-2 mb-0 font-weight-bolder text-danger">
											{{ translate('ticket_not_refundable_disclaimer') }}
										</p>
									</div>
								</div>
								<div class="row mx-3">
									<template v-if="tickets">
										<event-ticket
											v-for="(item, key) in tickets"
											:key="key"
											:ticket-code-name="item.code_name"
											:names="ticketsNames[key]"
											:quantity="item.qty"
											@dataChanged="saveTickets(key, item.code_name, $event)" />
									</template>
									<div
										v-if="ticketsError"
										class="col-12 mt-3">
										<b-alert
											variant="danger"
											show>
											<div
												v-for="error in validationErrors[`${steps[$route.name].validation}.tickets`]"
												:key="error">
												<span
													v-text="error" />
												<br>
											</div>
										</b-alert>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3">
								<div
									v-if="!loadingCartInfo"
									class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('select_shipping_method') }}</span>
										</div>
									</div>
									<template v-if="!loadingShippingMethods || cartInfo.is_virtual">
										<div class="col-12 mt-3">
											<b-alert
												v-if="!hasAddressId || cartInfo.is_virtual"
												:variant="cartInfo.is_virtual ? 'info' : 'warning'"
												show>
												{{ translate(cartInfo.is_virtual ? 'shipping_unnecessary' : 'incomplete_address_information') }}
											</b-alert>
											<b-alert
												v-else-if="!hasShippingMethods"
												variant="warning"
												show>
												{{ translate('no_address_found') }}
											</b-alert>
										</div>
										<div
											v-if="hasShippingMethods && hasAddressId"
											:class="windowWidth === 'xs' ? '': (windowWidth === 'sm' ? 'px-4' : 'px-5' )"
											class="col-12 col-lg-11 mt-3">
											<div
												v-for="({ attributes: item}, index) in shippingMethods"
												:key="`${item.carrier_code_name}_${item.method_code_name}`"
												class="form-check my-2">
												<div class="row no-gutters">
													<div class="col-auto d-flex align-items-center">
														<input
															:id="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
															v-model="shippingMethod"
															:value="{carrier_code_name: item.carrier_code_name, method_code_name: item.method_code_name}"
															:checked="index === 0"
															class="form-check-input"
															type="radio"
															name="shipping_method">
													</div>
													<div class="col-auto d-flex align-items-center pr-2">
														<img
															v-if="(typeof shippingMethodCodes[`${item.carrier_code_name}_${item.method_code_name}`]) !== 'undefined'"
															:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodCodes[`${item.carrier_code_name}_${item.method_code_name}`].image}`)"
															class="my-auto border"
															style="width: 45px; height: 30px;">
													</div>
													<div class="col d-flex align-items-center">
														<label
															:for="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
															class="form-check-label">
															{{ translate(`${ item.carrier_code_name }_${ item.method_code_name }`) }}
														</label>
													</div>
													<div class="col-auto d-flex align-items-center">
														<div class="float-right">
															{{ item.amount }}
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
									<is-loading
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										:loading="loadingShippingMethods"
										:has-data="true"
										class="mx-auto mt-4" />
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="!!preparing"
						variant="light"
						size="lg"
						@click="nextStep(true)">
						{{ translate('previous') }}
					</b-button>
					<b-button
						:disabled="validating || !!preparing || loadingShippingMethods || addAddress"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ADDRESSES, ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { UNPROCESSABLE, SYSTEM_ALERT } from '@/settings/Errors';
import {
	SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
	ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries,
	SHIPPING_UPDATABLE_FIELDS as shippingUpdatableFields,
	SHIPPING_METHOD_CODES as shippingMethodCodes,
} from '@/settings/Shipping';
import { TIMEZONE } from '@/settings/General';
import { GENDERS as gender, REQUIRED_NIN_COUNTRIES } from '@/settings/Profile';
import { MONTHS as months } from '@/settings/Dates';
import { BLACKLIST_UNSET_FIELDS } from '@/settings/Purchase';
import AddressBook from '@/util/AddressBook';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import EventTicket from '@/components/EventTicket';
import SwitchToggle from '@/components/Switch';
import InputText from '@/components/InputText';
import isLoading from '@/components/Loading';
import Cart from '@/util/Cart';
import Country from '@/util/Country';
import CommonMix from './mixins/Common';
import PackMix from './mixins/Package';
import { ADDITIONAL_ADDRESS_AGREEMENT_COUNTRIES } from '@/settings/AddressForm';

const { CancelToken } = require('axios');

export default {
	name: 'BecomeDistributorAccountAndShipping',
	components: {
		AddressCountryForm,
		AddressRadioButton,
		SwitchToggle,
		InputText,
		isLoading,
		EventTicket,
	},
	filters: {
		withCero: (value) => ((value <= 9) ? `0${value}` : value),
	},
	mixins: [CommonMix, PackMix, WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			isCompany: false,
			countryData: new Country(),
			stateData: new Country(),
			shippingData: new Cart(),
			cartData: new Cart(),
			shippingMethod: {},
			timing: null,
			gender,
			months,
			form: {
				national_identification_number: '',
				gender: gender[0],
				company: '',
				tax_id: '',
				birthdate: {
					day: '01',
					month: '01',
					year: '1900',
				},
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
				address_id: '',
			},
			allowAnotherCountry,
			blackListFields: BLACKLIST_UNSET_FIELDS,
			getSuggestion: new AddressBook(),
			countriesListForSignature,
			timezone: TIMEZONE,
			requiredNinCountries: REQUIRED_NIN_COUNTRIES,
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			showOverrideValidation: false,
			addressVerificationCountries,
			shippingMethodCodes,
			shippingUpdatableFields,
			cancel: () => null,

			addressBook: new AddressBook(),
			createAddress: new AddressBook(),
			loadAddress: new AddressBook(),
			addAddress: false,
			hasAddressId: false,
			ticketsNames: {},
			onlyTicketsNames: {},
			ticketsError: false,
			ADDRESSES,
			addressType: 'shipping',
			ADDITIONAL_ADDRESS_AGREEMENT_COUNTRIES,
		};
	},
	computed: {
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		shippingStep() {
			return Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
		},
		savedShipping() {
			const step = this.shippingStep;
			const { shipping } = { ...this.getStepInformation(step) };
			return shipping;
		},
		shippingMethods() {
			try {
				return this.shippingData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingShippingMethods() {
			try {
				return this.shippingData.data.loading;
			} catch (error) {
				return false;
			}
		},
		hasShippingMethods() {
			return !!this.shippingMethods.length;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		cartInfo() {
			try {
				return this.cartData.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		tickets() {
			try {
				return this.cartData.data.response.data.response.tickets;
			} catch (error) {
				return [];
			}
		},
		loadingCartInfo() {
			try {
				return this.cartData.data.loading;
			} catch (error) {
				return false;
			}
		},
		userCountry() {
			if (this.country) {
				return `nin_${this.country.toLowerCase()}`;
			}
			return 'national_identification_number';
		},
	},
	watch: {
		isCompany(value) {
			if (value === false) {
				this.form.national_identification_number = this.form.tax_id;
				this.form.tax_id = '';
				this.form.company = '';
			}
			if (value === true) {
				this.form.tax_id = this.form.national_identification_number;
				this.form.national_identification_number = '';
			}
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId(value) {
			this.clearValidationError('shipping.shipping_address.address_id');
			this.hasAddressId = false;
			if (value.length || !!value) {
				this.hasAddressId = true;
				this.setAddressInfo(value);
				this.getShippingMethods();
			}
		},
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.initializeAddress();
		this.getStoredInformation();
	},
	methods: {
		years() {
			const startingYear = 1900;
			const currentYear = this.$moment().year();
			const years = [];
			/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
			for (let index = startingYear; index <= currentYear; index++) {
				years.push(index);
			}
			return years;
		},
		initializeAddress() {
			this.addressBook.getAddresses(this.$user.details().id, { type: this.addressType }).then((response) => {
				if (this.form.address_id.length === 0 && response.length) this.form.address_id = response[0].id;
			});
		},
		setAddressInfo(addressId) {
			this.loadAddress.getAddress(this.$user.details().id, addressId).then((response) => {
				this.form.address_id = response.id;
			}).catch(() => {
				this.form.address_id = '';
				this.initializeAddress();
			});
		},
		getGeneralInformation() {
			// const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'personal_information');
			this.form.first_name = this.$user.details().name;
			this.form.last_name = this.$user.details().last_name;
			const [step] = Object.keys(this.steps).filter((item) => this.steps[item].validation === 'product');
			const previous = this.getStepInformation(step);
			this.cartData.getCartInfo(this.shoppingCartId);
			if (typeof previous.product === 'undefined' || Object.keys(previous.product).length < 1) {
				this.nextStep(true);
				return null;
			}
			return null;
		},
		getStoredInformation() {
			try {
				const { shipping } = { ...this.getStepInformation() };
				const { shipping_address: data } = this.savedShipping;
				this.shippingMethod = shipping.shipping_method;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
						if (key === 'override_validation') {
							this.showOverrideValidation = true;
						}
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}

				const dataTemp = { ...this.getStepInformation().personal_information };
				if (!Object.keys(dataTemp).length) {
					return null;
				}
				const [year, month, day] = dataTemp.birthdate.split('-');
				dataTemp.birthdate = { year, month, day };
				Object.keys(this.form).forEach((key) => {
					if (typeof dataTemp[key] !== 'undefined' && !this.blackListFields.includes(key)) {
						this.form[key] = dataTemp[key];
					}
				});

				// Remove invalid fields for the current country
				const personalData = this.clearInvalidFields(dataTemp);

				if (typeof personalData.is_company === 'boolean') {
					this.isCompany = personalData.is_company;
				}
			} catch (error) {
				//
			}
			if (this.hasAddressId) {
				this.getShippingMethods();
			}
			return null;
		},
		prepareForStorage() {
			this.preparing = true;
			const shipping = {
				shipping_address: { ...this.form },
				shipping_method: { carrier_code_name: null, method_code_name: null },
				tickets: this.onlyTicketsNames,
				tickets_info: this.ticketsNames,
			};

			const pickupAddress = this.addressData.find((address) => address.id === this.form.address_id && address.attributes.is_pickup_address);
			if (pickupAddress) {
				shipping.pickup_at_office = pickupAddress.attributes.warehouse_id;
			}

			if (this.hasAddressId) {
				shipping.shipping_address = { signature_required: this.form.signature_required, address_id: this.form.address_id };
			}
			if (typeof this.shippingMethod !== 'undefined') {
				if (Object.keys(this.shippingMethod).length) {
					shipping.shipping_method = { ...this.shippingMethod };
				}
			}

			const { year, month, day } = this.form.birthdate;
			const personalInformation = {
				gender: this.form.gender,
				company: this.form.company,
				is_company: this.isCompany,
				tax_id: this.form.tax_id,
				birthdate: `${year}-${month}-${day}`,
				national_identification_number: this.form.national_identification_number,
			};
			let payload = {
				step: this.steps[this.$route.name].validation, shipping, personal_information: personalInformation,
			};
			if (!this.showOverrideValidation) {
				payload.shipping.shipping_address.override_validation = undefined;
			}
			this.validateStep(payload).then((response) => {
				payload = { ...payload, shipping_data: { ...response.response } };
				this.saveStep(payload);
			}).catch((errors) => {
				if (UNPROCESSABLE.includes(errors.status)) {
					const { 'shipping.tickets': ticketsError } = errors.errors;
					if (typeof ticketsError !== 'undefined') {
						this.ticketsError = true;
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'shipping.shipping_address.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
			const targetValue = target.split('.').pop();
			if (this.shippingUpdatableFields.includes(targetValue)) this.getShippingMethods();
		},
		getShippingMethods() {
			this.clearValidationError('shipping.shipping_method.carrier_code_name');
			if (!this.hasAddressId || this.cartInfo.is_virtual) {
				return null;
			}
			if (this.timing) {
				clearTimeout(this.timing);
			}
			if (this.cancel) this.cancel();
			this.timing = setTimeout(() => {
				const payload = { address_id: this.form.address_id };
				this.shippingData.getShippingMethod(this.shoppingCartId, payload, {
					cancelToken: new CancelToken((c) => { this.cancel = c; }),
				}).then((response) => {
					if (response.length === 0) {
						return null;
					}

					this.shippingMethod = !Object.keys(this.shippingMethod).length ? { carrier_code_name: response[0].attributes.carrier_code_name, method_code_name: response[0].attributes.method_code_name } : this.shippingMethod;
					return null;
				}).catch(() => {}).finally(() => {
					this.cancel = null;
				});
			}, 500);
			return null;
		},
		isNINRequired() {
			return this.requiredNinCountries.includes(this.country);
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
			this.form.personal_document_number = '';
			this.form.personal_document_front_file = null;
			this.form.personal_document_back_file = null;
		},
		clearInvalidFields(data) {
			if (!this.isNINRequired()) {
				data.national_identification_number = '';
			}

			return data;
		},
		newAddress(form) {
			let options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.createAddress.saveAddress(this.$user.details().id, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id, { type: this.addressType }).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				});

				this.addAddress = !this.addAddress;
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								options = {
									config: {
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.newAddress(this.form);
								}).catch(() => {});
							}).catch(() => {});
						} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		saveTickets(sku, codeName, attendees) {
			this.ticketsError = false;
			this.ticketsNames[sku] = {
				code_name: codeName,
				names: attendees,
			};

			this.onlyTicketsNames[sku] = this.ticketsNames[sku].names;
		},
	},
};
</script>

<style>
	.address-list .card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
</style>
